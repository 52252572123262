<template>
  <div>
    <v-data-table :headers="headers"
                  dense
                  fixed-header
                  :options.sync="pageOptions"
                  :server-items-length="totalElements"
                  :disable-sort="true"
                  :hide-default-header="$vuetify.breakpoint.mobile"
                  :footer-props="{
                    disableItemsPerPage: true,
                    itemsPerPageOptions: [itemsPerPage],
                  }"
                  :items="elements" :loading="loading">
      <template v-slot:item.id="{item}">
        <strong>#{{ item.id }}</strong>
      </template>
      <template v-slot:item.nomeCliente="{item}">
        <span>{{retornaNomeCliente(item)}}</span>
      </template>      
      <template v-slot:item.provasFormatada="{item}">
        <span>{{retornaProvas(item.provasFormatada)}}</span>
      </template>      
      <template v-slot:item.dtInscricao="{item}">
        <span>{{item.dtInscricao | moment('DD/MM/YYYY')}}</span>
      </template>
      <template v-slot:item.tipoAluno="{item}">
        <span :class="corTipoAluno(item.tipoAluno)">{{ item.tipoAluno }}</span>
      </template>
      <template v-slot:item.status="{item}">
        <span>{{item.status == "A" ? "Ativo" : "Inativo"}}</span>
      </template>
      <template v-slot:item.valorInscricao="{item}">
        <span>{{retornaValorInscricao(item.valorInscricao)}}</span>
      </template>
      <template v-slot:item.fatura="{item}">
        <span :class="corFatura(item.fatura)">{{item.fatura}}</span>
      </template>

      <template v-slot:body="{items, isSelected, select}" v-if="$vuetify.breakpoint.mobile">
        <v-card v-if="items.length === 0" class="elevation-0">
          <v-card-text class="text-center">
            <p>Não há dados disponíveis. Utilize o filtro para refazer a pesquisa.</p>
          </v-card-text>
        </v-card>
        <v-list dense class="pa-0">
          <v-list-item v-for="item of items" :key="item.id"
                       class="list-item">
            <v-list-item-avatar>
              #{{ item.id }}
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>{{retornaNomeCliente(item)}} 
                <span :class="corTipoAluno(item.tipoAluno)">({{item.tipoAluno}})</span>
              </v-list-item-title>
              <v-list-item-subtitle>
                {{item.descInscricaoCompeticao}}
              </v-list-item-subtitle>
              <v-list-item-subtitle>
                {{retornaProvas(item.provasFormatada)}}
              </v-list-item-subtitle>
              <v-list-item-subtitle>
                <span :class="corFatura(item.fatura)">{{item.fatura}}</span> {{retornaValorInscricao(item.valorInscricao)}}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </template>

    </v-data-table>
  </div>
</template>

<script>

import {findAllClienteLogado} from '../../../api/inscricoes';
import ResponsiveMenu from "../../app/ResponsiveMenu";

/**
 * @emits click:edit
 * @emit click:remove
 */
export default {
  name: "InscricoesDataTable",
  components: {ResponsiveMenu},
  props: {
    searchValues: {
      type: Object,
    },
  },
  data() {
    return {
      selectedItem: null,
      menu: {
        positionX: 0,
        positionY: 0,
        show: false
      },
      loading: false,
      error: null,
      pageOptions: {
        page: 1
      },
      itemsPerPage: 0,
      totalElements: 0,
      elements: [],
      headers: [
        {
          value: 'id',
          text: 'ID',
          align: 'start',
        },
        {
          text: 'Tipo',
          value: 'tipoAluno',
          align: 'start',
          width: "5%"
        },
        {
          text: 'Cliente',
          value: 'nomeCliente',
          align: 'start'          
        },
        {
          text: 'Competição',
          value: 'descInscricaoCompeticao',
          align: 'start'
        },
        {
          text: 'Provas',
          value: 'provasFormatada',
          align: 'start'
        },        
        {
          text: 'Data Inscrição',
          value: 'dtInscricao',
          align: 'start'
        },
        {
          text: 'Fatura',
          value: 'fatura',
          align: 'start'
        },
        {
          text: 'Valor Inscrição',
          value: 'valorInscricao',
          align: 'start'
        },
        {
          text: 'Status',
          value: 'status',
          align: 'start'
        },        
      ],
    }
  },
  computed: {
    selectedItemName() {
      return this.selectedItem?.descricao
    },
  },
  watch: {
    searchValues: {
      deep: true,
      handler() {
        this.refresh();
      }
    },
    'pageOptions.page': {
      handler(newValue, oldValue) {
        if (newValue !== oldValue) this.refresh(newValue);
      }
    }
  },
  methods: {
    corTipoAluno(tipo){
      if (tipo=="Titular"){
          return "titular";
      } else {
          return "dependente";
      }
    },
    retornaValorInscricao(valorInscricao) {
      if (valorInscricao !== "") return "R$ " + valorInscricao;
      return "";
    },
    retornaNomeCliente(item){
      if (item.flgInscrAvulsa == 'S') return item.clienteAvulso;
      return item.nomeCliente;
    },
    retornaProvas(provas){
      if (provas.length == 0) return "--";
      return provas.map(d => d.itens.map(c => c.descricao).join(', ')).join(' e ');
    },
    corFatura(fatura) {
      return fatura.indexOf("Paga") >= 0 ? "vermelha" : (fatura.indexOf("Vencimento") >= 0 ? "verde" : "laranja") ;     
    },
    showMenu(event, item) {
      this.selectedItem = !item.item ? item : item.item;
      this.$refs.menu.open(event);
    },
    onClickRow(row) {
      this.$emit('click:row', row);
    },
    async refresh() {
      try {
        this.error = null;
        this.loading = true;
        const page = await findAllClienteLogado(this.pageOptions.page, this.searchValues);

        this.itemsPerPage = page.elementsPerPage;
        this.totalElements = page.totalElements;
        this.elements = page.elements;
        this.pageOptions.page = page.currentPage;

      } catch (err) {
        this.error = err;
      } finally {
        this.loading = false;
      }
    },
  },
  mounted() {
    this.pageOptions.page = 1;
    this.refresh();
  }
}
</script>

<style scoped lang="scss">
.list-item {
  &::v-deep {
    .v-list-item__content {
      max-width: calc(100vw - 128px);
    }
  }
}
.list-item:not(:last-child) {
  border-bottom: 1px solid #dedede
}
.vermelha { color: rgb(250, 7, 7) }
.verde { color: rgb(39, 95, 56) }
.laranja { color: rgb(243, 154, 36) }
</style>